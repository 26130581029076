import { AppShell, Burger, Group, MantineProvider, Menu } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import "./shell.css";
import { useState } from "react";
import {
  ShoppingCart,
  UserCircle,
  ShoppingCartOff,
  AlertCircle,
  MessageCircle,
  MapPin,
  User,
  FileOrientation,
  Hammer,
} from "tabler-icons-react";
import Orders from "./pages/order";
import Banner from "./pages/banner";
import Reviews from "./pages/reviews";
import Stores from "./pages/stores";
import Employees from "./pages/employees";
import errorProducts from "./pages/errorProducts";
import AbandonedCarts from "./pages/abandonedCarts";
import Articles from "./pages/articles";
import PromotionsAndNews from "./pages/PromotionsAndNews";
import EDO from "./pages/edo";
import PromoCodes from "./pages/promocode";
import Collection from "./pages/collection";
import ChangePasswordModal from "./components/modals/changePasswordModal";
import ConfirmExitModal from "./components/modals/confirmSignOutModal";

export function Shell() {
  const [active, setActive] = useState("");
  const [opened, { toggle }] = useDisclosure();
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [
    changePasswordOpened,
    { open: openChangePassword, close: closeChangePassword },
  ] = useDisclosure(false);
  const [
    confirmExitOpened,
    { open: openConfirmExit, close: closeConfirmExit },
  ] = useDisclosure(false);

  const BannerIcon = `${process.env.PUBLIC_URL}/icons/banner.svg`;
  const ArticlesIcon = `${process.env.PUBLIC_URL}/icons/article.svg`;
  const PercentageIcon = `${process.env.PUBLIC_URL}/icons/sale.svg`;
  const PromoCodeIcon = `${process.env.PUBLIC_URL}/icons/promocode.svg`;

  const options = [
    { link: "", label: "Заказы", icon: ShoppingCart, component: Orders },
    {
      link: "",
      label: "Акции и новости",
      icon: PercentageIcon,
      component: PromotionsAndNews,
    },
    { link: "", label: "Статьи", icon: ArticlesIcon, component: Articles },
    { link: "", label: "Баннеры", icon: BannerIcon, component: Banner },
    {
      link: "",
      label: "Промокоды",
      icon: PromoCodeIcon,
      component: PromoCodes,
    },
    {
      link: "",
      label: "Подборки товаров",
      icon: Hammer,
      component: Collection,
    },
    {
      link: "",
      label: "Брошенные корзины",
      icon: ShoppingCartOff,
      component: AbandonedCarts,
    },
    {
      link: "",
      label: "Товары с ошибкой",
      icon: AlertCircle,
      component: errorProducts,
    },
    { link: "", label: "Отзывы", icon: MessageCircle, component: Reviews },
    { link: "", label: "Магазины", icon: MapPin, component: Stores },
    { link: "", label: "Сотрудники", icon: User, component: Employees },
    { link: "", label: "ЭДО", icon: FileOrientation, component: EDO },
  ];

  const links = options.map((item) => (
    <a
      className="options mx-auto"
      data-active={item.label === active || undefined}
      href={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        setActive(item.label);
      }}
    >
      {typeof item.icon === "string" ? (
        <img
          src={item.icon}
          className="optionsIcon"
          style={{ color: "white" }}
          alt={item.label}
          width={25}
          height={25}
        />
      ) : (
        <item.icon className="optionsIcon" size={25} strokeWidth={2} />
      )}
      {opened && <span className="label">{item.label}</span>}
    </a>
  ));

  const ComponentToRender = options.find(
    (item) => item.label === active
  )?.component;

  return (
    <MantineProvider>
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: opened ? 210 : 50,
          breakpoint: "sm",
          collapsed: { mobile: !opened },
        }}
        padding="md"
      >
        <AppShell.Header>
          <div className="header-content">
            <Group px="md">
              <Burger opened={opened} onClick={toggle} size="md" />
              <img src="/logo.svg" alt="Logo" />
            </Group>
            <Group px="md">
              <Menu
                opened={dropdownOpened}
                onClose={() => setDropdownOpened(false)}
                onOpen={() => setDropdownOpened(true)}
              >
                <Menu.Target>
                  <Group>
                    <span className="user-info">
                      <span
                        style={{
                          color: "#506176",
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        Иван Иванов
                      </span>
                      <span style={{ color: "#506176", fontSize: "14px" }}>
                        контент-менеджер
                      </span>
                    </span>
                    <UserCircle size={40} strokeWidth={1} color={"#506176"} />
                  </Group>
                </Menu.Target>
                <Menu.Dropdown style={{ width: "200px", borderRadius: "12px" }}>
                  <Menu.Item onClick={openConfirmExit}>Выход</Menu.Item>
                  <Menu.Item onClick={openChangePassword}>
                    Изменить пароль
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </div>
        </AppShell.Header>
        <AppShell.Navbar style={{ padding: 0 }}>
          <div>{links}</div>
        </AppShell.Navbar>
        <AppShell.Main>
          {ComponentToRender && <ComponentToRender openedSideBar={opened} />}
        </AppShell.Main>
      </AppShell>
      <ChangePasswordModal
        opened={changePasswordOpened}
        close={closeChangePassword}
      />
      <ConfirmExitModal opened={confirmExitOpened} close={closeConfirmExit} />
    </MantineProvider>
  );
}

export default Shell;
