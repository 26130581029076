import { Button, Group } from "@mantine/core";
import "./main-pages.css";
import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import PromoCodeModal from "../components/promoCode/promoCodeModal";
import PromoCodeTable from "../components/promoCode/promoCodeTable";

interface PromoCodeFormValues {
  idPromoCode: string;
  title: string;
  creationDate: string;
  validityPeriod: string;
  category: string;
  status: string;
}

function PromoCodes({ openedSideBar }: { openedSideBar: boolean }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [modalTitle, setModalTitle] = useState("Создание промокода");

  const form = useForm<PromoCodeFormValues>({
    initialValues: {
      idPromoCode: "",
      title: "",
      creationDate: "",
      validityPeriod: "",
      category: "",
      status: "",
    },
  });

  const setFormValues = (promoCode: any) => {
    form.setValues({
      idPromoCode: promoCode.idPromoCode,
      title: promoCode.title,
      creationDate: promoCode.creationDate,
      validityPeriod: promoCode.validityPeriod,
      category: promoCode.category,
      status: promoCode.status,
    });
  };

  const handleCreatePromoCodeClick = () => {
    setModalTitle("Создание промокода");
    form.reset();
    open();
  };

  const handleAddPromoCode = (values: PromoCodeFormValues) => {
    // Handle add promo code logic here
  };

  return (
    <>
      <div className="header-content-main-pages" style={{ paddingLeft: 0 }}>
        <Group style={{ paddingLeft: 0 }}>
          <h1 className="basefont-h1">Промокоды</h1>
        </Group>
        <Group>
          <Button
            size="md"
            radius="md"
            style={{
              backgroundColor: "#0560BE",
              borderRadius: 12,
              fontSize: 15,
              marginBottom: 10,
            }}
            onClick={handleCreatePromoCodeClick}
          >
            + Создать промокод
          </Button>
        </Group>
      </div>
      <PromoCodeModal
        opened={opened}
        close={close}
        modalTitle={modalTitle}
        form={form}
        handleAddPromoCode={handleAddPromoCode}
      />
      <PromoCodeTable
        setFormValues={setFormValues}
        openModal={open}
        setModalTitle={setModalTitle}
        openedSideBar={openedSideBar}
      />
    </>
  );
}

export default PromoCodes;
