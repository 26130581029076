import {
  Button,
  MantineProvider,
  TextInput,
} from "@mantine/core";
import { useState } from "react";
import { MultiSelectCheckbox } from "../../shared/MultiSelectCheckbox";
import { CalendarEvent, Search } from "tabler-icons-react";
import { DatePickerInput } from "@mantine/dates";
import TablePattern from "../table-pattern";
import Paginator from "../../shared/paginator";

interface PromoCode {
  idPromoCode: string;
  title: string;
  creationDate: string;
  validityPeriod: string;
  category: string;
  status: string;
}

interface PromoCodeTableProps {
  setFormValues: (values: any) => void;
  openModal: () => void;
  setModalTitle: (title: string) => void;
  openedSideBar: boolean;
}

const PromoCodeTable: React.FC<PromoCodeTableProps> = (props) => {
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [searchId, setSearchId] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [total, setTotal] = useState<number>(25);

  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);

  const handleResetFilters = () => {
    setDateValue([null, null]);
    setSearchId("");
    setSelectedCategory([]);
    setSelectedStatus([]);
  };

  const handleFilterChange = (filterKey: string, filterValue: any) => {
    if (filterValue.includes("Все")) {
      filterValue = [];
    }
  };

  const filtersPattern = (
    <>
      <TextInput
        style={{ flex: 2, marginTop: "25px" }}
        placeholder="Поиск"
        leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
        value={searchId}
        size="md"
        radius="md"
        onChange={(event) => {
          setSearchId(event.currentTarget.value);
          if (event.currentTarget.value === "") {
            handleFilterChange("idPromoCode", []);
          } else {
            handleFilterChange("idPromoCode", [event.currentTarget.value]);
          }
        }}
      />
      <DatePickerInput
        style={{ flex: 1 }}
        styles={(theme) => ({
          input: {
            fontSize: props.openedSideBar ? `calc(100% - 5px)` :'' ,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        })}
        valueFormat="DD.MM.YYYY"
        minDate={new Date(0)}
        maxDate={new Date()}
        type="range"
        label="Дата создания"
        placeholder="Любая"
        rightSection={
          <CalendarEvent size={20} color="#506176" strokeWidth={1.5} />
        }
        size="md"
        radius="md"
        value={dateValue}
        onChange={(newValue) => {
          setDateValue(newValue);
          if (newValue[0] === null && newValue[1] === null) {
            handleFilterChange("creationDate", []);
          } else {
            handleFilterChange("creationDate", newValue);
          }
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Категория"
        data={[
          "Все",
          "Бонусы за заказ",
          "Бонусы за условия",
          "День рождения",
          "Подарок при заказе",
          "Скидка за условия",
          "Снижение цены",
        ]}
        value={selectedCategory}
        onChange={(value: any) => {
          setSelectedCategory(value);
          handleFilterChange("category", value);
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Статус активности"
        data={["Все", "Активен", "Не активен"]}
        value={selectedStatus}
        onChange={(value: any) => {
          setSelectedStatus(value);
          handleFilterChange("status", value);
        }}
      />
      <Button
        onClick={handleResetFilters}
        size="md"
        radius="sm"
        style={{
          flex: 0.3,
          backgroundColor: "white",
          color: "#25262B",
          borderRadius: 12,
          fontSize: 15,
          width: "120px",
          marginTop: "25px",
        }}
      >
        Сбросить
      </Button>
    </>
  );

  const headers = [
    "ID промокода",
    "Название",
    "Дата создания",
    "Период действия",
    "Категория",
    "Статус активности",
  ];

  const data = [
    {
      idPromoCode: "123123-123124",
      title: "Скидка2025",
      creationDate: "02.06.2023",
      validityPeriod: "с 07.06.2023 по 12.07.2023",
      category: "Бонусы за условия",
      status: "Активен",
    },
  ];

  const getFieldToStyle = () => "status";

  const getStatusStyle = (status: string) => {
    switch (status) {
      case "Активен":
        return {
          backgroundColor: "#DEF7F0",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
          border: "1px solid #E0E0E0",
        };
      case "Не активен":
        return {
          backgroundColor: "#FDE3E3",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
          border: "1px solid #E0E0E0",
        };
      default:
        return {};
    }
  };

  const handleRowClick = (promoCode: PromoCode) => {
    props.setFormValues(promoCode);
    props.openModal();
    props.setModalTitle("Редактировать промокод");
  };

  // Filter the data to only include the fields that are in the headers
  const filteredData = data.map((promoCode) => ({
    idPromoCode: promoCode.idPromoCode,
    title: promoCode.title,
    creationDate: promoCode.creationDate,
    validityPeriod: promoCode.validityPeriod,
    category: promoCode.category,
    status: promoCode.status,
  }));

  return (
    <MantineProvider>
      <div>
        <TablePattern
          filters={filtersPattern}
          headers={headers}
          data={filteredData}
          getFieldToStyle={getFieldToStyle}
          getStyle={getStatusStyle}
          onRowClick={handleRowClick}
          customDataType="promoCode"
          customData={data}
          activePage={activePage}
          selectedRow={selectedRows}
        />
        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default PromoCodeTable;
