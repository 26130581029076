import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Login from './pages/login/login';
import Main from './pages/main';
function App() {
  return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/main" element={<Main />} />
                <Route path="/login" element={<Login />} />
            </Routes>
        </Router>
  )
}

export default App;
