import { Modal, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button } from "../../shared/button";

interface EmployeeFormValues {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  password: string;
  position: string;
  status: string;
}

interface EmployeeModalProps {
  opened: boolean;
  close: () => void;
  modalTitle: string;
  form: ReturnType<typeof useForm<EmployeeFormValues>>;
  handleAddEmployee: () => void;
}

const EmployeeModal: React.FC<EmployeeModalProps> = ({
  opened,
  close,
  modalTitle,
  form,
  handleAddEmployee,
}) => {
  const fieldStyles = {};

  return (
    <Modal
      opened={opened}
      onClose={close}
      size="auto"
      title={modalTitle}
      centered
      className="personal-model"
    >
      <form onSubmit={form.onSubmit(handleAddEmployee)}>
        <TextInput
          label="Имя"
          placeholder="Введите имя"
          style={{ padding: "12px" }}
          value={form.values.firstName}
          {...form.getInputProps("firstName")}
          styles={fieldStyles}
        />
        <TextInput
          label="Фамилия"
          placeholder="Введите фамилию"
          style={{ padding: "12px" }}
          value={form.values.lastName}
          {...form.getInputProps("lastName")}
          styles={fieldStyles}
        />
        <TextInput
          label="Отчество"
          placeholder="Введите отчество"
          style={{ padding: "12px" }}
          value={form.values.middleName}
          {...form.getInputProps("middleName")}
          styles={fieldStyles}
        />
        <TextInput
          label="Почта"
          placeholder="Введите почту"
          style={{ padding: "12px" }}
          {...form.getInputProps("email")}
          styles={fieldStyles}
        />

        <TextInput
          label={
            modalTitle === "Добавить сотрудника" ? "Пароль" : "Новый пароль"
          }
          placeholder={
            modalTitle === "Добавить сотрудника"
              ? "Введите пароль"
              : "Введите новый пароль"
          }
          style={{ padding: "12px" }}
          {...form.getInputProps("password")}
          value={form.values.password}
          styles={fieldStyles}
        />
        <Select
          label="Должность"
          placeholder="Выберите должность"
          size="md"
          radius="md"
          style={{ padding: "12px" }}
          value={form.values.position}
          data={["администратор", "менеджер ИМ", "контент-менеджер"]}
          {...form.getInputProps("position")}
        />

        {modalTitle !== "Добавить сотрудника" && (
          <Select
            label="Статус активности"
            placeholder="Выберите статус"
            data={["Активен", "Не Активен"]}
            style={{ padding: "12px" }}
            value={form.values.status}
            size="md"
            radius="md"
            {...form.getInputProps("status")}
          />
        )}

        <div
          className="flex flex-col gap-2 mt-2"
          style={{
            justifyContent:
              modalTitle === "Добавить сотрудника" ? "center" : "space-between",
          }}
        >
          <Button
            type="submit"
            style={{
              backgroundColor: "#0560BE",
              width: "410px",
              height: "48px",
              padding: "8px, 20px, 8px, 20px",
              marginLeft: "10px",
            }}
          >
            {modalTitle === "Добавить сотрудника" ? "Добавить" : "Сохранить"}
          </Button>
          {modalTitle !== "Добавить сотрудника" && (
            <Button
              variant="outline"
              onClick={close}
              style={{
                width: "410px",
                height: "48px",
                padding: "8px, 20px, 8px, 20px",
                marginLeft: "10px",
              }}
            >
              Отменить
            </Button>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default EmployeeModal;
