import React from "react";
import { Button, Modal, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

interface ChangePasswordModalProps {
  opened: boolean;
  close: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  opened,
  close,
}) => {
  const form = useForm({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    // Handle password change logic
    console.log("Password changed:", values);
    close();
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      title="Смена пароля"
      centered
      className="personal-model"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          label="Старый пароль"
          placeholder="Введите текущий пароль"
          style={{ padding: "12px" }}
          {...form.getInputProps("oldPassword")}
        />
        <TextInput
          label="Новый пароль"
          placeholder="Введите новый пароль"
          style={{ padding: "12px" }}
          {...form.getInputProps("newPassword")}
        />
        <TextInput
          label="Повторите новый пароль"
          placeholder="Повторите новый пароль"
          style={{ padding: "12px" }}
          {...form.getInputProps("confirmPassword")}
        />
        <Button
          type="submit"
          style={{
            backgroundColor: "#0560BE",
            width: "410px",
            height: "48px",
            padding: "8px, 20px, 8px, 20px",
            marginLeft: "10px",
            marginTop: "12px",
          }}
        >
          Сохранить
        </Button>
        <Button
          onClick={close}
          variant="outline"
          style={{
            width: "410px",
            height: "48px",
            padding: "8px, 20px, 8px, 20px",
            marginLeft: "10px",
            marginTop: "12px",
          }}
        >
          Отменить
        </Button>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
