import { useState } from "react";
import { Button, Group } from "@mantine/core";
import "./main-pages.css";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import EmployeeTable from "../components/employees/employeeTable";
import EmployeeModal from "../components/employees/employeeModal";

interface EmployeeFormValues {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  password: string;
  position: string;
  status: string;
}

const Employees = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [modalTitle, setModalTitle] = useState("Добавить сотрудника");
  const form = useForm<EmployeeFormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      email: "",
      password: "",
      position: "",
      status: "Активен",
    },
  });

  const setFormValues = (employee: any) => {
    form.setValues({
      firstName: employee.firstName,
      lastName: employee.lastName,
      middleName: employee.middleName,
      email: employee.email,
      password: employee.password,
      position: employee.position,
      status: employee.status,
    });
  };

  const handleAddEmployee = () => {
    // Handle add employee logic here
  };

  const handleCreateEmployeeClick = () => {
    setModalTitle("Добавить сотрудника");
    form.reset(); // Reset form values for creating a new employee
    open();
  };

  return (
    <>
      <div className="header-content-main-pages" style={{ paddingLeft: 0 }}>
        <Group style={{ paddingLeft: 0 }}>
          <h1 className="basefont-h1">Сотрудники</h1>
        </Group>
        <Group>
          <Button
            size="md"
            radius="md"
            style={{
              backgroundColor: "#0560BE",
              borderRadius: 12,
              fontSize: 15,
              marginBottom: 10,
            }}
            onClick={handleCreateEmployeeClick}
          >
            + Добавить сотрудника
          </Button>
        </Group>
      </div>
      <EmployeeModal
        opened={opened}
        close={close}
        modalTitle={modalTitle}
        form={form}
        handleAddEmployee={handleAddEmployee}
      />
      <EmployeeTable
        setFormValues={setFormValues}
        openModal={open}
        setModalTitle={setModalTitle}
      />
    </>
  );
};

export default Employees;
