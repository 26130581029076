import {
  Button as MantineButton,
  Group,
  Select,
  TextInput,
  Card,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { ChevronLeft } from "tabler-icons-react";
import { RTE } from "../../shared/richTextEditor";
import { Button } from "../../shared/button";

interface PromotionFormValues {
  idPromotion: string;
  title: string;
  creationDate: string;
  publicationDate: string;
  tag: string;
  status: string;
  content: string;
}

interface PromotionFormProps {
  title: string;
  initialValues: PromotionFormValues;
  onSubmit: (value: boolean, promotion: PromotionFormValues | null) => void;
}

const PromotionForm: React.FC<PromotionFormProps> = ({
  title,
  initialValues,
  onSubmit,
}) => {
  const form = useForm<PromotionFormValues>({ initialValues });
  const [rteContent, setRteContent] = useState(initialValues.content);

  const handleFormSubmit = (values: PromotionFormValues) => {
    onSubmit(true, { ...values, content: rteContent });
  };

  const handleCancel = () => {
    form.reset();
    onSubmit(false, null);
  };

  return (
    <div>
      <MantineButton
        leftSection={<ChevronLeft size={20} />}
        onClick={handleCancel}
        style={{
          backgroundColor: "#ffffff",
          color: "#25262B",
          fontSize: "16px",
          padding: 0,
          border: "#ffffff",
          transition: "background-color 0.3s",
          marginBottom: "2%",
        }}
      >
        Назад
      </MantineButton>
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <div className="flex flex-col gap-6">
          <Group
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "2%",
            }}
          >
            <h1 className="basefont-h1">{title}</h1>
            {title === "Редактировать акцию или новость" ? (
              <div style={{ display: "flex", gap: "1rem" }}>
                <Button
                  variant="outline"
                  onClick={handleCancel}
                  style={{
                    width: "200px",
                    height: "48px",
                    padding: "8px, 20px, 8px, 20px",
                    marginLeft: "10px",
                  }}
                >
                  Отменить
                </Button>
                <Button
                  type="submit"
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#0560BE",
                    width: "200px",
                    height: "48px",
                    padding: "8px, 20px, 8px, 20px",
                    marginLeft: "10px",
                  }}
                >
                  Сохранить
                </Button>
              </div>
            ) : (
              <Button
                type="submit"
                style={{
                  color: "#ffffff",
                  backgroundColor: "#0560BE",
                  width: "200px",
                  height: "48px",
                  padding: "8px, 20px, 8px, 20px",
                  marginLeft: "10px",
                }}
              >
                Сохранить
              </Button>
            )}
          </Group>

          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-2 space-y-4">
              <Card
                shadow="sm"
                padding="lg"
                radius="xl"
                withBorder
                className="rounded-3xl gap-4"
              >
                <Select
                  label="Тег"
                  placeholder="Выберите тег"
                  data={["Акция", "Новость"]}
                  {...form.getInputProps("tag")}
                  styles={{
                    input: {
                      borderRadius: "12px",
                      height: "44px",
                      marginBottom: "12px",
                    },
                  }}
                />
                <TextInput
                  label="Название"
                  placeholder="Введите название"
                  {...form.getInputProps("title")}
                  styles={{
                    input: {
                      borderRadius: "12px",
                      height: "44px",
                      marginBottom: "12px",
                    },
                  }}
                />

                <TextInput
                  label="Дата акции"
                  placeholder="Выберите дату действия акции"
                  {...form.getInputProps("publicationDate")}
                  styles={{
                    input: {
                      borderRadius: "12px",
                      height: "44px",
                      marginBottom: "12px",
                    },
                  }}
                />
                <RTE content={rteContent} setContent={setRteContent} />
                <Button
                  type="submit"
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#0560BE",
                    width: "360px",
                    height: "48px",
                    padding: "8px, 20px, 8px, 20px",
                    marginLeft: "10px",
                  }}
                >
                  Сохранить
                </Button>
              </Card>
            </div>
            <div className="space-y-4">
              <Card
                shadow="sm"
                padding="lg"
                radius="xl"
                withBorder
                className="rounded-3xl gap-4"
              >
                <h2 className="basefont-h2">Статус </h2>
                <Select
                  label="Статус активности"
                  placeholder="Выберите статус"
                  data={["Активна", "Не активна"]}
                  {...form.getInputProps("status")}
                  styles={{
                    input: {
                      borderRadius: "12px",
                      height: "44px",
                      marginBottom: "12px",
                    },
                  }}
                />
              </Card>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PromotionForm;
