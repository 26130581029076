import { Button, MantineProvider } from "@mantine/core";
import { useEffect, useState } from "react";
import { ChevronLeft } from "tabler-icons-react";
import TablePattern from "../table-pattern";
import { fetchCartDetails, fetchCartProducts } from "../../lib/api/carts";
import { Cart, Product } from "../../lib/api/types/carts";
import { formatDate } from "date-fns";
import { formatPhone, formatRuble } from "../../lib/utils";
import Paginator from "../../shared/paginator";

const AbandonedCartDetails = ({
  cartId,
  closeDetails,
}: {
  cartId: string;
  closeDetails: () => void;
}) => {
  const [cart, setCart] = useState<Cart | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);
  const [total, setTotal] = useState<number>(25);

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      const cartResponse = await fetchCartDetails(cartId);
      setCart(cartResponse.cart);
      const offset = (activePage - 1) * selectedRowsNumber;
      const productsResponse = await fetchCartProducts(cartId, { offset: offset, limit: selectedRowsNumber });
      console.log(productsResponse)
      setProducts(productsResponse.products);
      setTotal(productsResponse.total);
      setLoading(false);
    };

    fetchDetails();
  }, [cartId, , activePage, selectedRows]);

  const clientHeaders = [
    "ID клиента",
    "Клиент",
    "Номер телефона",
    "Дата создания",
    "E-mail",
    "Сумма корзины",
    "Кол-во поз-й",
  ];

  const productHeaders = [
    "ID товара",
    "Название",
    "Цена",
    "Дата добавления",
    "Кол-во (шт)",
    "Тег",
  ];

  const clientData = [
    {
      "ID клиента": cart?.user.id,
      Клиент:
        cart?.user.first_name +
        " " +
        cart?.user.last_name +
        " " +
        cart?.user.father_name,
      "Номер телефона": cart?.user.phone && formatPhone(cart.user.phone),
      "Дата создания":
        cart?.modified && formatDate(cart.modified, "dd.MM.yyyy"),
      "E-mail": cart?.user.email,
      "Сумма корзины": cart?.price_total && formatRuble(cart.price_total),
      "Кол-во поз-й": cart?.positions_count,
    },
  ];

  const productData = products.map((product) => ({
    "ID товара": product.product_id,
    "Название": product.name,
    "Цена": product.price,
    "Дата добавления": formatDate(product.added, "dd.MM.yyyy"),
    "Кол-во (шт)": product.quantity,
    'Тег': product.is_available ? 'В наличии' : 'Товар закончился',
  }));

  const getFieldToStyle = () => "Тег";

  const getTagStyle = (tag: string) => {
    switch (tag) {
      case "В наличии":
        return {
          backgroundColor: "#DEF7F0",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      case "Товар закончился":
        return {
          backgroundColor: "#FDE3E3",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      default:
        return {};
    }
  };

  return (
    <MantineProvider>
      <div>
        <Button
          leftSection={<ChevronLeft size={20} />}
          onClick={closeDetails}
          style={{
            backgroundColor: "#ffffff",
            color: "#25262B",
            fontSize: "16px",
            padding: 0,
            border: "#ffffff",
            transition: "background-color 0.3s",
            marginBottom: "2%",
          }}
        >
          Назад
        </Button>
        <h1 className="basefont-h1">Брошенная корзина</h1>
        <TablePattern
          headers={clientHeaders}
          data={clientData}
          loading={loading}
          activePage={0}
          selectedRow={'0'}
        />
        <h2 className="basefont-h2 mt-4">Товары</h2>
        <TablePattern
          headers={productHeaders}
          data={productData}
          loading={loading}
          // getFieldToStyle={getFieldToStyle}
          getStyle={getTagStyle}
          customDataType="abandonedCart"
          activePage={0}
          selectedRow={'0'}
        />
        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default AbandonedCartDetails;
