import {
  Group,
  TextInput,
  Radio,
  Button as MantineButton,
  Select,
  Loader
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { ChakraProvider, useToast } from '@chakra-ui/react'
import { ChevronLeft } from "tabler-icons-react";
import { useEffect, useState } from "react";
import StoreComponent from "./StoreComponent";
import DeliveryComponent from "./DeliveryComponent";
import PickupPointComponent from "./PickupPointComponent";
import { Button } from "../../shared/button";
import { Order } from "../../lib/api/types/orders";
import axiosInstance from "../../lib/api/base";
import { getOrderById } from "../../lib/api/orders";
import { getUserById } from "../../lib/api/user";
import ProductsTable from "./product-table";
import { formatRuble } from "../../lib/utils";

interface EditOrderProps {
  editOrder: (value: boolean, order: Order | null) => void;
  orderNumber: number | null;
}

const PHONE_REGEX = /^[0-9]+$/;
const DEFAULT_PREFIX = "+7";

const EditOrder: React.FC<EditOrderProps> = ({ editOrder, orderNumber }) => {
  const selectedHeaders = [
    "ID товара на сайте",
    "Наименование товара",
    "Характеристики",
    "Стоимость",
    "Количество",
    "Действие",
  ];

  const selectHeaders = [
    "ID товара на сайте",
    "Наименование товара",
    "Характеристики",
    "Стоимость",
    "Действие",
  ];

  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [attemptId, setAttemptId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [productList, setProductList] = useState<
    { id: string; quantity: number }[] | null
  >(null);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [sendCodeDisabled, setSendCodeDisabled] = useState(false);
  const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(false);
  const toast = useToast()
  const [phoneIsVerified, setPhoneIsVerified] = useState(false)
  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      code: "",
      orderNumber: 0,
      created: "",
      status: "",
      user_id: "",
      receive_type: "",
      payment_method: "",
      receipt_url: null,
      price_delivery: 0,
      discount_bonuses: 0,
      total_price: 0,
      bonuses_added: 0,
      bonuses_spent: 0,
      comment: "",
    },
    validate: {
      name: (value) =>
        /\b\w+\s\w+\s\w+\b/.test(value) ? null : "Введите полное ФИО",
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Некорректный email",
      phone: (value) =>
        !PHONE_REGEX.test(value.replace("+", ""))
          ? "Номер телефона может содержать только цифры"
          : null,
      code: (value) =>
        value.length === 4 && PHONE_REGEX.test(value)
          ? null
          : "Введите 4 цифры",
    },
  });

  useEffect(() => {
    if (orderNumber) {
      // Fetch the order data
      const fetchOrder = async () => {
        try {
          setLoading(true);
          const order = await getOrderById(orderNumber);
          const user = await getUserById(order.order.user_id);
          setTotalPrice(order.order.total_price);
          form.setValues({
            name: `${user.user.first_name} ${user.user.last_name} ${user.user.father_name}`,
            email: user.user.email,
            phone: user.user.phone,
            orderNumber: order.order.number,
            created: new Date(order.order.created).toLocaleDateString("ru-RU"),
            total_price: totalPrice,
            status: order.order.status,
            discount_bonuses: order.order.discount_bonuses,
          });
          setProductList(
            order.order.products.map((product) => ({
              id: product.id,
              quantity: product.quantity,
            }))
          );

          setLoading(false);
        } catch (error) {
          console.error("Error fetching order", error);
        }
      };
      fetchOrder();
    } else {
      setLoading(false);
    }
  }, [orderNumber]);

  const formatPhoneNumber = (value: string) => {
    // Remove all non-digit characters
    const digitsOnly = value.replace(/\D/g, "");
    // Add +7 if it doesn't exist
    if (!digitsOnly.startsWith("7")) {
      return `${DEFAULT_PREFIX}${digitsOnly}`;
    }
    return `+${digitsOnly}`;
  };

  const handleSendCode = async () => {
    try {
      const response = await axiosInstance.post("/users/verify-phone", {
        phone: form.values.phone.slice(1),
      });
      setAttemptId(response.data.attempt_id);
      setIsCodeSent(true);
      toast({
        title: 'Код отправлен.',
        description: "Ваш код был успешно отправлен на указанный номер телефона.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setSendCodeDisabled(true);
      setTimeout(() => {
        setSendCodeDisabled(false);
      }, 10000);


    } catch (error) {
      console.error("Error sending verification code", error);
      toast({
        title: 'Ошибка отправки кода.',
        description: "Не удалось отправить код. Пожалуйста, попробуйте еще раз.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleVerifyCode = async () => {
    try {
      if (!attemptId) return;
      const verifyCode = {
        attempt_id: attemptId,
        code: parseInt(form.values.code),
      }
      await axiosInstance.post("/users/verify-phone-complete", verifyCode);
      toast({
        title: 'Код подтвержден.',
        description: "Ваш код был успешно подтвержден.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setVerifyButtonDisabled(true);
      setPhoneIsVerified(true)
    } catch (error) {
      console.error("Error verifying phone code", error);
      toast({
        title: 'Ошибка подтверждения кода.',
        description: "Не удалось подтвердить код. Пожалуйста, проверьте код и попробуйте еще раз.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setPhoneIsVerified(false)
    }
  };

  const handleCancel = () => {
    form.reset();
    editOrder(false, null);
  };

  const handleCreateOrder = async () => {
    console.log(productList);
    if (!orderNumber) {
      // Create new order logic
    } else {
      // Update existing order logic
    }
  };

  const [deliveryMethod, setDeliveryMethod] = useState<string>("store");

  const handleDeliveryMethodChange = (value: string) => {
    setDeliveryMethod(value);
  };

  const handleProduct = (productId: string, type: string, price: number) => {
    setProductList((prevProducts) => {
      const products = prevProducts || [];

      if (type === "select") {
        const productExists = products.some((product) => product.id === productId);
        if (!productExists) {
          setTotalPrice(totalPrice + price);
          return [...products, { id: productId, quantity: 1 }];
        }
        return products;
      } else if (type === "selected") {
        const updatedProducts = products.filter((product) => product.id !== productId);
        const productToRemove = products.find((product) => product.id === productId);

        if (productToRemove) {
          setTotalPrice(totalPrice - productToRemove.quantity * price);
        }

        return updatedProducts;
      }

      return products;
    });
  };

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Loader size="lg" />
      </div>
    );
  }
  return (
    <>
      <ChakraProvider>
        <MantineButton
          className="back-button"
          leftSection={<ChevronLeft size={20} />}
          onClick={handleCancel}
          style={{
            backgroundColor: "#ffffff",
            color: "#25262B",
            fontSize: "16px",
            padding: 0,
            border: "#ffffff",
            transition: "background-color 0.3s",
            marginBottom: "2%",
          }}
        >
          Назад
        </MantineButton>

        <div className="flex flex-col gap-6">
          <Group
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 className="basefont-h1">
              {orderNumber ? "Редактирование заказа" : "Создание заказа"}
            </h1>
            <Group>
              <Button
                type="button"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#ffffff",
                  color: "#1E2134",
                  width: "200px",
                }}
              >
                Отменить
              </Button>
              {orderNumber && (
                <Button
                  type="button"
                  onClick={handleCreateOrder}
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#0560BE",
                    width: "200px",
                  }}
                >
                  Сохранить
                </Button>
              )}
            </Group>
          </Group>
          <h2 className="basefont-h2">Информация о клиенте</h2>
          {orderNumber ? (
            <>
              <Group>
                <TextInput
                  style={{ flex: 1 }}
                  label="ID заказа"
                  size="md"
                  radius="md"
                  value={form.values.orderNumber}
                  disabled
                />
                <TextInput
                  style={{ flex: 1 }}
                  label="Дата создания заказа"
                  disabled
                  value={form.values.created}
                  size="md"
                  radius="md"
                />
              </Group>
              <Group>
                <TextInput
                  style={{ flex: 1 }}
                  label="ФИО"
                  size="md"
                  radius="md"
                  disabled
                  value={form.values.name}
                />
                <TextInput
                  style={{ flex: 1 }}
                  label="Номер телефона"
                  size="md"
                  radius="md"
                  disabled
                  value={form.values.phone}
                />
              </Group>
              <Group>
                <TextInput
                  style={{ flex: 0.5 }}
                  label="E-mail"
                  size="md"
                  radius="md"
                  value={form.values.email}
                  disabled
                />
              </Group>
            </>
          ) : (
            <>
              <Group>
                <TextInput
                  style={{ flex: 1 }}
                  label="ФИО"
                  placeholder="Введите ФИО клиента"
                  size="md"
                  radius="md"
                  {...form.getInputProps("name")}
                />
                <TextInput
                  style={{ flex: 1 }}
                  label="E-mail"
                  placeholder="Введите e-mail клиента"
                  {...form.getInputProps("email")}
                  size="md"
                  radius="md"
                />
              </Group>
              <Group>
                <TextInput
                  style={{ flex: 1 }}
                  label="Номер телефона"
                  placeholder="Введите номер телефона клиента"
                  {...form.getInputProps("phone")}
                  size="md"
                  radius="md"
                  onChange={(event) => {
                    const value = event.currentTarget.value;
                    if ((PHONE_REGEX.test(value.replace("+", "")) || value === "") &&
                      value.length <= 12) {
                      form.setFieldValue("phone", formatPhoneNumber(value));
                    }
                  }}
                  error={form.errors.phone}
                  rightSection={
                    <div
                      onClick={sendCodeDisabled || !form.values.phone ? undefined : handleSendCode}
                      style={{
                        display: "flex",
                        marginRight: "55px",
                        cursor: sendCodeDisabled || !form.values.phone || form.values.phone.length !== 12 ? "not-allowed" : "pointer",
                        opacity: sendCodeDisabled || !form.values.phone || form.values.phone.length !== 12 ? 0.5 : 1,
                        pointerEvents: sendCodeDisabled || !form.values.phone || form.values.phone.length !== 12 ? "none" : "auto"
                      }}
                    >
                      <span className="text-default-blue w-[100px] basefont-t6">
                        {sendCodeDisabled ? "Подождите..." : "Выслать код"}
                      </span>
                    </div>
                  }
                />
                <TextInput
                  style={{ flex: 1 }}
                  label="Подтверждение номера телефона"
                  placeholder="Введите код из смс"
                  size="md"
                  radius="md"
                  {...form.getInputProps("code")}
                  maxLength={4}
                  onChange={(event) => {
                    const value = event.currentTarget.value;
                    // Allow only digits and max 4 characters
                    if (
                      (PHONE_REGEX.test(value) || value === "") &&
                      value.length <= 4
                    ) {
                      form.setFieldValue("code", value);
                    }
                  }}
                  error={form.errors.code}
                  rightSection={
                    <div
                      onClick={handleVerifyCode}
                      style={{
                        display: "flex",
                        marginRight: "66px",
                        cursor: !form.values.code || form.values.code.length !== 4 || verifyButtonDisabled ? "not-allowed" : "pointer",
                        opacity: !form.values.code || form.values.code.length !== 4 || verifyButtonDisabled ? 0.5 : 1
                      }}
                    >
                      <span className="text-default-blue w-[100px] basefont-t6">
                        Подтвердить
                      </span>
                    </div>
                  }
                />
              </Group>
            </>
          )}

          <Group style={{ display: "flex", justifyContent: "space-between" }}>
            <h2 className="basefont-h2">Добавленные товары</h2>
            <h2 className="basefont-h2">Итого: {formatRuble(totalPrice)}</h2>
          </Group>
          <Group>
            <ProductsTable
              headers={selectedHeaders}
              productsList={productList}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              type="selected"
              setProductList={setProductList}
              handleProduct={handleProduct}
            />
          </Group>

          <Group>
            <h2 className="basefont-h2">Выбор товаров</h2>
            <ProductsTable
              headers={selectHeaders}
              productsList={productList}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              setProductList={setProductList}
              type="select"
              handleProduct={handleProduct}
            />
          </Group>
          <h2 className="basefont-h2">Способ получения заказа</h2>
          <Group
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Radio.Group
              value={deliveryMethod}
              onChange={handleDeliveryMethodChange}
            >
              <Group
                mt="xs"
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <Radio variant="outline" value="store" label="Самовывоз" />
                <Radio variant="outline" value="delivery" label="Доставка" />
                <Radio variant="outline" value="pickup_point" label="Пункт выдачи" />
              </Group>
            </Radio.Group>
            {deliveryMethod === "store" && <StoreComponent />}
            {deliveryMethod === "delivery" && <DeliveryComponent />}
            {deliveryMethod === "pickup_point" && <PickupPointComponent />}
          </Group>
          <h2 className="basefont-h2">Способ оплаты</h2>
          <Group
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Radio.Group>
              <Group
                mt="xs"
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <Radio variant="outline" value="card" label="Картой онлайн" />
                <Radio variant="outline" value="prepayment" label="При получении" />
              </Group>
            </Radio.Group>
          </Group>

          <h2 className="basefont-h2">Промокод</h2>
          <Group>
            <TextInput
              style={{ width: "648px" }}
              label="Промокод"
              size="md"
              radius="md"
              rightSection={
                <div style={{ display: "flex", marginRight: "66px" }}>
                  <span className="text-default-blue w-[87px] cursor-pointer basefont-t6">
                    Применить
                  </span>
                </div>
              }
            />
            <span className="bg-[#99BEE4] w-full rounded-xl p-[8px] text-primary">
              {" "}
              Промокод СКИДКА успешно применен
            </span>
          </Group>
          <h2 className="basefont-h2">Бонусы</h2>
          <Group>
            <TextInput
              style={{ width: "648px" }}
              label="Бонусы"
              size="md"
              radius="md"
              rightSection={
                <div style={{ display: "flex", marginRight: "66px" }}>
                  <span className="text-default-blue w-[87px] cursor-pointer basefont-t6">
                    Применить
                  </span>
                </div>
              }
            />
            <span className="bg-[#99BEE4] w-full rounded-xl p-[8px] text-primary">
              {" "}
              Будет списано 123 бонуса
            </span>
          </Group>

          {orderNumber && (
            <>
              <h2 className="basefont-h2">Статус заказа</h2>
              <Group>
                <Select
                  style={{ width: "50%" }}
                  className="select-edit-order"
                  label="Статус заказа"
                  data={[
                    "Заказ оплачен",
                    "Заказ отменен",
                    "В аренде",
                    "Ожидает оплату",
                    "Бесплатный отказ",
                  ]}
                  size="md"
                  radius="md"
                  value={form.values.status}
                  onChange={(value) =>
                    form.setFieldValue("status", value as string)
                  }
                />
              </Group>
            </>
          )}

          <Group
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "30px",
            }}
          >
            <h1 className="basefont-h1">
              Общая стоимость: {formatRuble(totalPrice)}
            </h1>
            <Button
              style={{
                display: "inline-block",
                padding: "8px, 20px, 8px, 20px",
                backgroundColor: "#0560BE",
                color: "#ffffff",
                border: "1px solid #0560BE",
                borderRadius: 12,
                fontSize: 15,
                textAlign: "center",
                width: "360px",
                height: "54px",
                cursor: "pointer",
              }}
            >
              {orderNumber ? "Сохранить изменения" : "Создать заказ"}
            </Button>
          </Group>
        </div>
      </ChakraProvider>
    </>

  );
};

export default EditOrder;
