import { Modal, Select, TextInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { CalendarEvent } from "tabler-icons-react";
import { Button } from "../../shared/button";

interface PromoCodeFormValues {
  idPromoCode: string;
  title: string;
  creationDate: string;
  validityPeriod: string;
  category: string;
  status: string;
}

interface PromoCodeModalProps {
  opened: boolean;
  close: () => void;
  modalTitle: string;
  form: ReturnType<typeof useForm<PromoCodeFormValues>>;
  handleAddPromoCode: (values: PromoCodeFormValues) => void;
}

const PromoCodeModal: React.FC<PromoCodeModalProps> = ({
  opened,
  close,
  modalTitle,
  form,
  handleAddPromoCode,
}) => {
  return (
    <Modal
      opened={opened}
      onClose={close}
      size="auto"
      title={modalTitle}
      centered
      className="personal-model"
    >
      <form onSubmit={form.onSubmit(handleAddPromoCode)}>
        <TextInput
          label="Название"
          placeholder="Введите название"
          style={{ padding: "12px" }}
          value={form.values.title}
          onChange={(event) =>
            form.setFieldValue("title", event.currentTarget.value)
          }
        />
        <DatePickerInput
          label="Период действия"
          placeholder="Выберите период действия"
          style={{ padding: "12px" }}
          valueFormat="DD.MM.YYYY"
          rightSection={
            <CalendarEvent size={20} color="#506176" strokeWidth={1.5} />
          }
          type="range"
          radius="md"
          onChange={(value: any) =>
            form.setFieldValue("validityPeriod", value!)
          }
        />
        <Select
          label="Категория"
          placeholder="Выберите категорию"
          data={[
            "Бонусы за заказ",
            "Бонусы за условия",
            "День рождения",
            "Подарок при заказе",
            "Скидка за условия",
            "Снижение цены",
          ]}
          size="md"
          radius="md"
          style={{ padding: "12px" }}
          value={form.values.category}
          onChange={(value) => form.setFieldValue("category", value!)}
        />
        <Select
          label="Статус активности"
          placeholder="Выберите статус активности"
          data={["Активен", "Не активен"]}
          size="md"
          radius="md"
          style={{ padding: "12px" }}
          value={form.values.status}
          onChange={(value) => form.setFieldValue("status", value!)}
        />
        <Button
          type="submit"
          style={{
            backgroundColor: "#0560BE",
            width: "410px",
            height: "48px",
            padding: "8px, 20px, 8px, 20px",
            marginLeft: "10px",
            marginTop: "12px",
          }}
        >
          {modalTitle.includes("Создание") ? "Добавить" : "Сохранить"}
        </Button>
        <div className="flex flex-col gap-2 mt-2">
          {modalTitle === "Редактировать промокод" && (
            <Button
              variant="outline"
              onClick={close}
              style={{
                width: "410px",
                height: "48px",
                padding: "8px, 20px, 8px, 20px",
                marginLeft: "10px",
              }}
            >
              Отменить
            </Button>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default PromoCodeModal;
