import { Button, Group } from "@mantine/core";
import "./main-pages.css";
import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import PromotionForm from "../components/promotions/promotionForm";
import PromotionTable from "../components/promotions/promotion-table";

interface PromotionFormValues {
  idPromotion: string;
  title: string;
  creationDate: string;
  publicationDate: string;
  tag: string;
  status: string;
  content: string;
}

function PromotionsAndNews({ openedSideBar }: { openedSideBar: boolean }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [modalTitle, setModalTitle] = useState("Создание акции или новости");

  const form = useForm<PromotionFormValues>({
    initialValues: {
      idPromotion: "",
      title: "",
      creationDate: "",
      publicationDate: "",
      tag: "",
      status: "",
      content: "",
    },
  });

  const setFormValues = (promotion: any) => {
    form.setValues({
      idPromotion: promotion.idPromotion,
      title: promotion.title,
      creationDate: promotion.creationDate,
      publicationDate: promotion.publicationDate,
      tag: promotion.tag,
      status: promotion.status,
      content: promotion.content,
    });
  };

  const handleCreatePromotionClick = () => {
    setModalTitle("Создание акции или новости");
    form.reset();
    open();
  };

  const handleAddPromotion = (values: PromotionFormValues) => {
    // Handle add promotion logic here
  };

  const handleFormSubmit = (
    submitted: boolean,
    values: PromotionFormValues | null
  ) => {
    if (submitted && values) {
      handleAddPromotion(values);
    }
    close();
  };

  return (
    <>
      {opened ? (
        <PromotionForm
          title={modalTitle}
          initialValues={form.values}
          onSubmit={handleFormSubmit}
        />
      ) : (
        <>
          <div className="header-content-main-pages" style={{ paddingLeft: 0 }}>
            <Group style={{ paddingLeft: 0 }}>
              <h1 className="basefont-h1">Акции и новости</h1>
            </Group>
            <Group>
              <Button
                size="md"
                radius="md"
                style={{
                  backgroundColor: "#0560BE",
                  borderRadius: 12,
                  fontSize: 15,
                  marginBottom: 10,
                }}
                onClick={handleCreatePromotionClick}
              >
                + Создать акцию
              </Button>
            </Group>
          </div>
          <PromotionTable
            setFormValues={setFormValues}
            openModal={open}
            setModalTitle={setModalTitle}
            openedSideBar={openedSideBar}
          />
        </>
      )}
    </>
  );
}

export default PromotionsAndNews;
