import {
  Button,
  MantineProvider,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { MultiSelectCheckbox } from "../../shared/MultiSelectCheckbox";
import { CalendarEvent, Search } from "tabler-icons-react";
import { DatePickerInput } from "@mantine/dates";
import TablePattern from "../table-pattern";
import Paginator from "../../shared/paginator";

interface EmployeeTableProps {
  setFormValues: (values: any) => void;
  openModal: () => void;
  setModalTitle: (title: string) => void;
}

const EmployeeTable: React.FC<EmployeeTableProps> = (props) => {
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [searchId, setSearchId] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedPosition, setSelectedPosition] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [total, setTotal] = useState<number>(25);

  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);

  const handleResetFilters = () => {
    setDateValue([null, null]);
    setSearchId("");
    setSelectedStatus([]);
    setSelectedPosition([]);
  };

  const handleFilterChange = (filterKey: string, filterValue: any) => {
    if (filterValue.includes("Все")) {
      filterValue = [];
    }
  };

  const filtersPattern = (
    <>
      <TextInput
        style={{ flex: 1, marginTop: "25px" }}
        placeholder="Поиск"
        leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
        value={searchId}
        size="md"
        radius="md"
        onChange={(event) => {
          setSearchId(event.currentTarget.value);
          if (event.currentTarget.value === "") {
            handleFilterChange("idEmployee", []);
          } else {
            handleFilterChange("idEmployee", [event.currentTarget.value]);
          }
        }}
      />
      <DatePickerInput
        style={{ flex: 1 }}
        valueFormat="DD.MM.YYYY"
        minDate={new Date(0)}
        maxDate={new Date()}
        type="range"
        label="Дата создания"
        placeholder="Любая"
        rightSection={
          <CalendarEvent size={20} color="#506176" strokeWidth={1.5} />
        }
        size="md"
        radius="md"
        value={dateValue}
        onChange={(newValue) => {
          setDateValue(newValue);
          if (newValue[0] === null && newValue[1] === null) {
            handleFilterChange("creationDate", []);
          } else {
            handleFilterChange("creationDate", newValue);
          }
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Должность"
        data={["Все", "администратор", "менеджер ИМ", "контент-менеджер"]}
        value={selectedPosition}
        onChange={(value: any) => {
          setSelectedPosition(value);
          handleFilterChange("position", value);
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Статус активности"
        data={["Все", "Активен", "Не активен"]}
        value={selectedStatus}
        onChange={(value: any) => {
          setSelectedStatus(value);
          handleFilterChange("status", value);
        }}
      />

      <Button
        onClick={handleResetFilters}
        size="md"
        radius="sm"
        style={{
          flex: 0.3,
          backgroundColor: "white",
          color: "#25262B",
          borderRadius: 12,
          fontSize: 15,
          width: "120px",
          marginTop: "25px",
        }}
      >
        Сбросить
      </Button>
    </>
  );

  const headers = [
    "ID сотрудника",
    "Дата создания",
    "ФИО",
    "Должность",
    "Почта",
    "Статус активности",
  ];

  const data = [
    {
      idEmployee: "123124-123124",
      creationDate: "03.06.2023",
      firstName: "Иван",
      lastName: "Иванов",
      middleName: "Иванович",
      password: "123456",
      email: "ivan@mail.ru",
      position: "администратор",
      status: "Активен",
    },
  ];

  const getFieldToStyle = () => "status";

  const getStatusStyle = (status: string) => {
    switch (status) {
      case "Активен":
        return {
          backgroundColor: "#DEF7F0",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      case "Не активен":
        return {
          backgroundColor: "#FDE3E3",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      default:
        return {};
    }
  };

  const handleRowClick = (employee: any) => {
    props.setFormValues(employee);
    props.openModal();
    props.setModalTitle("Редактировать данные");
  };

  // Filter the data to only include the fields that are in the headers
  const filteredData = data.map((employee) => ({
    idEmployee: employee.idEmployee,
    creationDate: employee.creationDate,
    fullName: `${employee.lastName} ${employee.firstName} ${employee.middleName}`,
    position: employee.position,
    email: employee.email,
    status: employee.status,
  }));

  return (
    <MantineProvider>
      <div>
        <TablePattern
          filters={filtersPattern}
          headers={headers}
          data={filteredData}
          getFieldToStyle={getFieldToStyle}
          getStyle={getStatusStyle}
          onRowClick={handleRowClick}
          customDataType="employee"
          customData={data}
          activePage={activePage}
          selectedRow={selectedRows}
        />
        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default EmployeeTable;
