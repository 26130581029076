/* eslint-disable no-unused-vars */
import React from "react";
import { useForm } from "@mantine/form";
import { MantineProvider, TextInput } from "@mantine/core";
import "./login.css";
import { Button } from "../../shared/button";
function Login() {
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
  });

  const handleSubmit = async () => {
    try {
      // const result = await login(values).unwrap();
      //console.log(result);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <MantineProvider>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <div
          style={{
            margin: "0 auto",
            textAlign: "center",
            width: "464px",
            height: "332px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          className="card border border-stroke-light rounded-xl px-[32px] py-[24px] flex flex-col gap-4"
        >
          <span className="basefont-h2 text-left ">Вход в систему</span>
          <TextInput
            required
            label="Логин"
            placeholder="Введите логин"
            value={form.values.email}
            onChange={(event) =>
              form.setFieldValue("email", event.currentTarget.value)
            }
            styles={{
              label: {
                textAlign: "left",
                fontWeight: "400",
                display: "block",
                marginBottom: "4px",
              },
            }}
          />
          <TextInput
            required
            label="Пароль"
            placeholder="Введите пароль"
            type="password"
            value={form.values.password}
            onChange={(event) =>
              form.setFieldValue("password", event.currentTarget.value)
            }
            styles={{
              label: {
                textAlign: "left",
                fontWeight: "400",
                display: "block",
                marginBottom: "4px",
              },
            }}
          />
          <Button
            type="submit"
            className=" w-[400px] h-[48px] mt-[10px] mx-auto"
            onSubmit={handleSubmit}
          >
            ВОЙТИ
          </Button>
        </div>
      </form>
    </MantineProvider>
  );
}

export default Login;
